.recommended-products {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.recommended-product {
    display: grid;

    grid-template-areas: 
        "image title button"
        "image price button"    
    ;

    grid-template-columns: 2fr 6fr 1fr;
    column-gap: 15px;

    .product-thumbnail {
        grid-area: image;
        img {
            max-width: 100%;
            height: auto;
        }
    }

    .product-title {
        grid-area: title;
        color: #000;
        font-size: 12px;
        font-weight: 500;
    }

    .quick-view-button {
        grid-area: button;
        font-size: 12px;
        line-height: 1;
        padding: 5px;
        align-self: center;
    }

    .product-price {
        grid-area: price;
    }
}